/* styles.css */
.header {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    color: #333333;
  }
  
  .navbar {
    display: flex;
    justify-content: flex-end;
  }
  
  .navbar a {
    text-decoration: none;
    color: #555555;
    margin-left: 1rem;
    transition: color 0.3s ease;
  }
  
  .navbar a:hover {
    color: #007bff; /* Warna biru lebih cerah saat hover */
  }
  