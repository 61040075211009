/* Dalam file CSS terpisah atau di dalam komponen ShelterDetail.css */
.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 300px;
    border-radius: 10px;
    margin-left: 10px;
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    color: white !important;
    background-color: transparent !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
  }
  
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: rgba(255, 255, 255, 0.5) !important; 
  }
  